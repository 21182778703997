/*global Pusher*/

import {extend} from 'flarum/extend';
import app from 'flarum/app';
import SignUpModal from 'flarum/components/SignUpModal';
import LogInModal from 'flarum/components/LogInModal';

app.initializers.add('lubobill1990-wechat', () => {
  let isInWeixinBrowser = navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1;

  function openLoginWindow() {
    let loginUrl = '/auth/wechat-web/redirect';
    if (isInWeixinBrowser) {
      loginUrl = '/auth/wechat-client/redirect';
    }
    let oauthWindow = window.open(loginUrl, 'Wechat Login', 'height=540,width=600');
    let interval = setInterval(() => {
      if (oauthWindow.window.wechatOauthSuccess) {
        clearInterval(interval);
        oauthWindow.close();
        location.reload();
      }
    }, 1000)
  }

  extend(SignUpModal.prototype, 'footer', function (items) {
    items.push(<a onclick={openLoginWindow}>{app.translator.trans('zhishiq.wechat.forum.signup_text')}</a>);
  });
  extend(LogInModal.prototype, 'footer', function (items) {
    items.push(<a onclick={openLoginWindow}>{app.translator.trans('zhishiq.wechat.forum.login_text')}</a>);
  });
});
