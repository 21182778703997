/*global Pusher*/

import {extend} from 'flarum/extend';
import app from 'flarum/app';
import SignUpModal from 'flarum/components/SignUpModal';

app.initializers.add('zhishiq-integration', () => {
  extend(SignUpModal.prototype, 'body', function (items) {
    items.splice(0, items.length);
    items.push(<p style="text-align:center">{app.translator.trans('zhishiq.integration.forum.log_in_with', {a: <a onclick={this.logIn.bind(this)} />})}</p>)
  });
});
